import '../css/app.css'
import 'element-plus/dist/index.css'

import {createApp, h} from 'vue'
import {createPinia} from 'pinia'
import ElementPlus from 'element-plus'
import {createInertiaApp} from '@inertiajs/vue3'
import {ZiggyVue} from '../../vendor/tightenco/ziggy/dist/vue.m'
import {resolvePageComponent} from 'laravel-vite-plugin/inertia-helpers'
import MetaPixel from '@/plugins/meta-pixel';

import * as Sentry from '@sentry/vue'

const pinia = createPinia()

createInertiaApp({
    title: (title) => `${title}`,
    resolve: (name) =>
        resolvePageComponent(
            `./Pages/${name}.vue`,
            import.meta.glob('./Pages/**/*.vue')
        ),
    setup({el, App, props, plugin}) {
        const app = createApp({render: () => h(App, props)})
            .use(pinia)
            .use(plugin)
            .use(ZiggyVue)
            .use(ElementPlus)
            .use(MetaPixel)
        const isStagingOrProduction =
            import.meta.env.VITE_APP_ENV === 'staging' ||
            import.meta.env.VITE_APP_ENV === 'production'

        if (isStagingOrProduction) {
            Sentry.init({
                app,
                dsn: 'https://4af4be1eb5b9457f630f95cb7b1d426d@o4506082179088384.ingest.us.sentry.io/4508381890150400',
                ignoreErrors: [/TypeError/, /Network Error/, /AxiosError/],
                beforeSend(event) {
                    if (event.exception && event.exception.values) {
                        const shouldIgnore = event.exception.values.some(
                            (exception) => {
                                const isTypeError =
                                    exception.type === 'TypeError'
                                const isNetworkError =
                                    exception.value &&
                                    exception.value.includes('Network Error')

                                return isTypeError || isNetworkError
                            }
                        )

                        if (shouldIgnore) {
                            return null
                        }
                    }
                    return event
                },
                ignoreUrls: [/.*node_modules.*/, /.*public\/build.*/],
                integrations: [
                    Sentry.browserTracingIntegration(),
                    // Sentry.replayIntegration(),
                ],
                tracesSampleRate: 1.0,
                tracePropagationTargets: [
                    'localhost',
                    new RegExp(`^${import.meta.env.VITE_APP_URL}`),
                ],
                replaysSessionSampleRate: 0.1,
                replaysOnErrorSampleRate: 1.0,
                environment: import.meta.env.VITE_APP_ENV,
            })
        }

        app.mount(el)
    },
})
